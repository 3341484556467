<template>
  <div class="w-100 h-100 pt-3">
    <article class="quick-instruction-box-container">
      <section id="instruction-0" class="quick-instruction-box">
        <p>Method A</p>
        <p>
          <span style="font-size: 200%">
            If you see a
            <img
              src="@/assets/icon-install-chrome-2024.svg"
              style="
                width: 1.5em;
                height: 1.5em;
                position: relative;
                bottom: 0.3em;
              " />
            icon<br
          /></span>
          in your address bar above, click it <br />
          and then <code>Install</code> or <code>Open</code>.
        </p>
      </section>
    </article>

    <br />
    <article class="slow-instruction-box-container">
      <section id="instruction-1" class="slow-instruction-box">
        <div class="d-flex align-items-end">
          <div>
            <p>Method B</p>
            <div class="d-flex flex-column align-items-end">
              <div>
                1. Click the
                <b-button variant="dark"><strong>&vellip;</strong></b-button>
                icon in corner of browser
              </div>
              <b-button
                v-b-toggle.collapse-browser-menu
                variant="link"
                class="p-0"
              >
                I don't have this icon
              </b-button>
            </div>
          </div>
          <img
            src="./InstallationArrow.svg"
            style="margin-left: 2em; width: 10em"
          />
        </div>
        <b-collapse id="collapse-browser-menu">
          <b-card style="max-width: 40em">
            <ul>
              <li>Check you are using Google Chrome.</li>
              <br />
              <li>
                If that corner contains a
                <b-button variant="dark" size="sm"><strong>⇧</strong></b-button>
                icon instead, click it to update Chrome.
              </li>
              <br />
              <li>
                Tell the app team your problem is "<code
                  >No triple-dot icon in {{ device.browser.name }}
                  {{ device.browser.version }} on {{ device.os.name }}
                  {{ device.os.version }}</code
                >".
              </li>
            </ul>
            <b-button v-b-toggle.collapse-browser-menu variant="light"
              >Close</b-button
            >
          </b-card>
        </b-collapse>
        <div>2. Click <code>Save and share</code></div>
        <div>
          3. Choose <code>Install Unity Imaging</code> <br /><span class="ml-3"
            >&nbsp;</span
          >or <code>Open in Unity Imaging"</code>
        </div>
        <b-button
          v-b-toggle.collapse-chrome-install-or-open
          variant="link"
          class="p-0 ml-3"
          >&nbsp;My Save and share menu has neither of these</b-button
        >
        <b-collapse id="collapse-chrome-install-or-open">
          <b-card style="max-width: 40em">
            <ul>
              <li>
                Make sure you are looking <em>inside</em> the "Save and share"
                option on the menu.
              </li>
              <br />
              <li>
                Does your browser address bar show one of these icons?
                <br /><img
                  src="@/assets/icon-open-in-app.svg"
                  class="app-opener-icon"
                />
                <img
                  src="@/assets/icon-install-desktop.svg"
                  class="app-opener-icon"
                />
                <img
                  src="@/assets/icon-install-mobile.svg"
                  class="app-opener-icon"
                />

                &nbsp; If so, try clicking it.
              </li>
              <br />

              <li>
                If the icon in the corner is
                <b-button variant="dark" size="sm"><strong>⇧</strong></b-button>
                instead of
                <b-button variant="dark" size="sm"
                  ><strong>&vellip;</strong></b-button
                >, click it to update Chrome.
              </li>
              <br />

              <li>
                If none of the above works, run the automated diagnostic test as
                follows:
                <ol>
                  <li>
                    In the
                    <b-button variant="dark" size="sm"
                      ><strong>&vellip;</strong></b-button
                    >
                    menu, choose "More tools" and then "Developer tools".
                  </li>
                  <li>
                    Click the small
                    <b-button variant="dark" size="sm"
                      ><strong>»</strong></b-button
                    >
                    near the top right, and select "Lighthouse".
                  </li>

                  <li>
                    In the Lighthouse tab, select "Progressive Web App" and
                    "Desktop" and deselect all other options.
                  </li>
                  <li>
                    Click "Generate report" or "Analyze page load" in the
                    Lighthouse tab.
                  </li>
                  <li>
                    After it generates your report, expand the top section
                    ("Installable") and copy its contents, including the
                    "Failure reason" subsection, and send to the app team, so we
                    can resolve it for you.
                  </li>
                </ol>
              </li>
              <br />
            </ul>

            <b-button v-b-toggle.collapse-chrome-install-or-open variant="light"
              >Close</b-button
            >
          </b-card>
        </b-collapse>
      </section>

      <!-- <br />
      <section id="instruction-4" class="instruction-box">
        <h3>
          Frequent user?
          <b-button v-b-toggle.collapse-shortcut variant="link" class="py-0"
            >Shortcut</b-button
          >
        </h3>
      </section>
      <section id="help-4">
        <b-collapse id="collapse-shortcut">
          <b-card style="">
            <h3>Installing a shortcut to Unity Imaging</h3>

            <ul>
              <li>
                In your browser address bar, click whichever of these icons you
                see:
                <img
                  src="@/assets/icon-open-in-app.svg"
                  class="app-opener-icon"
                />
                <img
                  src="@/assets/icon-install-desktop.svg"
                  class="app-opener-icon"
                />
                <img
                  src="@/assets/icon-install-mobile.svg"
                  class="app-opener-icon"
                />
              </li>
              <li>Click <code>Install</code> or <code>Open</code></li>
              <li>
                Click <code>Yes</code> if offered the option to add to your
                taskbar
              </li>
            </ul>
            <b-button v-b-toggle.collapse-shortcut variant="light"
              >Close</b-button
            >
          </b-card>
        </b-collapse>
      </section> -->

      <br />
      <br />
    </article>
  </div>
</template>

<script>
export default {
  props: {
    device: { type: Object, required: true },
    deviceToPromptInstallation: { type: String, required: true },
  },
};
</script>

<style scoped>
.app-opener-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin: 0 0.5em;
}

.quick-instruction-box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.quick-instruction-box {
  padding: 2em;
  background-color: #f0eff0;
  border-radius: 0.5em;
}

.slow-instruction-box-container {
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}

.slow-instruction-box {
  padding: 2em;
  margin-bottom: 0;
  background-color: #f0eff0;
  border-radius: 0.5em;
}
</style>
